import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import BreadCrumbs from "../../components/BreadCrumbs";
import CSharpEntwicklungHeader from "../../components/UeberMich/CSharpEntwicklungHeader";


export default function CSharpDotNetEntwicklung() {
    const Breadcrumbs = [
        { name: 'Ueber mich', href: '#', current: false },
        { name: 'C#/.NET-Entwicklung', href: '/UeberMich/CSharpDotNetEntwicklung', current: true },
    ]

    return (
        <>
            <Layout>
                <Seo title="C#/.NET-Entwicklung"/>
                <BreadCrumbs pages={Breadcrumbs}/>

                <CSharpEntwicklungHeader />
            </ Layout>
        </>
    )
}