import * as React from "react";

import { ViewGridAddIcon, GlobeAltIcon, TableIcon, ShieldCheckIcon, CubeTransparentIcon } from '@heroicons/react/outline'
import {StaticImage} from "gatsby-plugin-image";

const backendItems = [
    {
        id: 1,
        name: 'WebAPI-Entwicklung / Microservices',
        description:
            'Zu meinen Standardaufgaben gehört die Entwicklung von stabilen, wartbaren und performanten WebAPIs.',
        icon: GlobeAltIcon,
    },
    {
        id: 2,
        name: 'Objekt-orientiertes Design (OOD)',
        description:
            'Weil gutes OOD die Basis für wartbare Software ist, habe ich viel Zeit mit dem Studium von OOD verbracht. Dies zahlt sich für meine Kunden aus.',
        icon: CubeTransparentIcon,
    },
    {
        id: 3,
        name: 'Test-Driven-Development (TDD)',
        description:
            'Mittlerweile bin ich überzeugt, dass TDD eine der besten Entwicklungsphilosophien ist. Daher praktiziere ich TDD standardmäßg.',
        icon: ShieldCheckIcon,
    },
]
const frontendItems = [
    {
        id: 1,
        name: 'UX Design',
        description:
            'UX Design gehört zu meinen Steckenpferden. Es spornt mich an, durch kreative Lösungen meine Programme so benutzerfreundlich wie möglich zu gestalten.',
        icon: ViewGridAddIcon,
    },
    {
        id: 2,
        name: 'WinForms-Programme',
        description:
            'WinForms-Programme gehören zu meinen bevorzugten Frameworks, wenn man in .NET Frontends entwickeln möchte. Gerne setze ich dazu DevExpress ein.',
        icon: TableIcon,
    },
]

export default function CSharpEntwicklungHeader() {
    return (
        <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
            <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <svg
                    className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
                    width={404}
                    height={784}
                    fill="none"
                    viewBox="0 0 404 784"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
                </svg>

                <div className="relative">
                    <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                        C# / .NET-Entwicklung
                    </h2>
                    <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                        Was Sie über meine Kenntnisse der C#/.NET-Entwicklung wissen sollten.
                    </p>
                </div>

                <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                    <div className="relative">
                        <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                            Backend-Entwicklung
                        </h3>
                        <p className="mt-3 text-lg text-gray-500">
                            Bei den meisten meiner Projekte habe ich Backend-Komponenten entwickelt. Immer häufiger wurden
                            Microservice-Architekturen angefragt, bis hin zu DDD-Systemen.
                        </p>

                        <dl className="mt-10 space-y-10">
                            {backendItems.map((item) => (
                                <div key={item.id} className="relative">
                                    <dt>
                                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-diit text-white">
                                            <item.icon className="h-6 w-6" aria-hidden="true" />
                                        </div>
                                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                                    </dt>
                                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>

                    <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                        <svg
                            className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                            width={784}
                            height={404}
                            fill="none"
                            viewBox="0 0 784 404"
                        >
                            <defs>
                                <pattern
                                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                        </svg>


                        <StaticImage
                            src="../../images/sourcecode.png"
                            formats={["auto", "webp", "avif"]}
                            alt="Source Code aus C#-Programm"
                            width={490}
                            loading="eager"
                            className="relative mx-auto filter drop-shadow-2xl"
                        />

                    </div>
                </div>

                <svg
                    className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
                    width={404}
                    height={784}
                    fill="none"
                    viewBox="0 0 404 784"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
                </svg>

                <div className="relative mt-12 sm:mt-16 lg:mt-24">
                    <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                        <div className="lg:col-start-2">
                            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Frontend-Entwicklung</h3>
                            <p className="mt-3 text-lg text-gray-500">
                                »Das Auge ißt mit« gilt nicht nur beim Essen, sondern auch bei der Entwicklung von Frontends. Ansprechende Programme werden
                                nämlich wesentlich häufiger von Anwendern akzeptiert, als solche, die aussehen, wie vor 20 Jahren.
                            </p>

                            <dl className="mt-10 space-y-10">
                                {frontendItems.map((item) => (
                                    <div key={item.id} className="relative">
                                        <dt>
                                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-diit text-white">
                                                <item.icon className="h-6 w-6" aria-hidden="true" />
                                            </div>
                                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                                        </dt>
                                        <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                    </div>
                                ))}
                            </dl>
                        </div>

                        <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                            <svg
                                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                                width={784}
                                height={404}
                                fill="none"
                                viewBox="0 0 784 404"
                                aria-hidden="true"
                            >
                                <defs>
                                    <pattern
                                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                                        x={0}
                                        y={0}
                                        width={20}
                                        height={20}
                                        patternUnits="userSpaceOnUse"
                                    >
                                        <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                    </pattern>
                                </defs>
                                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
                            </svg>
                            <StaticImage
                                src="../../images/frontend.png"
                                formats={["auto", "webp", "avif"]}
                                alt="Front-end"
                                width={490}
                                loading="lazy"
                                className="relative mx-auto filter drop-shadow-2xl"
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
